<template>
  <b-container>
        <b-card>
          <h1 class="mb-0">Inputs</h1>
        </b-card>
  </b-container>
</template>
<script>
import { BContainer, BRow, BCol, BCard } from "bootstrap-vue";
export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BCard,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
