<template>
  <div class="container">
    <div class="d-flex align-items-center justify-content-between" style="gap:30px">
      <div class="card p-1 w-100">
        <div class="d-flex align-items-center justify-content-between">
          <div>
            <h5>Sessions</h5>
            <h3 class="mt-1 mb-0"> + {{ formattedNumber(countUsersGlobal) }}</h3>
          </div>
          <div>
            <feather-icon size="70" class="text-white bg-light-warning rounded-circle" style="padding: 15px"
              icon="UsersIcon" />
          </div>
        </div>
      </div>
      <div class="card p-1 w-100">
        <div class="d-flex align-items-center justify-content-between">
          <div>
            <h5>Users</h5>
            <h3 class="mt-1 mb-0"> + {{ formattedNumber(countUsersUnique) }}</h3>
          </div>
          <div>
            <feather-icon size="70" class="text-white bg-light-success rounded-circle" style="padding: 15px"
              icon="UsersIcon" />
          </div>
        </div>
      </div>
      <b-link class="card p-1 unique-card w-100" to="/admin-dash/event-count" >
        <div class="d-flex align-items-center justify-content-between">
          <div>
            <h5>Event Count</h5>
            <h3 class="mt-1 mb-0 ">+ {{ formattedNumber(eventCount) }} </h3>
          </div>
          <div>
            <feather-icon size="70" class="text-white bg-light-primary rounded-circle" style="padding: 15px"
              icon="TypeIcon" />
          </div>
        </div>
      </b-link>
    </div>
    <b-card title="Last 10 Users Connected">
      <b-table striped hover responsive class="position-relative mb-0" :items="users" :fields="fields">
      </b-table>
    </b-card>
  </div>
</template>
<script>

import { BContainer, BRow, BCol, BCard, BTable, BLink } from "bootstrap-vue";
import axios from "@axios";
export default {
  name: 'usersConnect',
  components: {
    BContainer,
    BRow,
    BCol,
    BCard,
    BTable, 
    BLink
  },
  data() {
    return {
      countUsersGlobal: 0,
      countUsersUnique: 0,
      eventCount: 0,
      users: [],
      fields: [
        {
          key: "index",
          label: "#",
          class: "text-center",
        },
        {
          key: "first_name",
          label: "first name",
          class: "text-center",
        },
        {
          key: "last_name",
          label: "last name",
          class: "text-center",
        },
        {
          key: "email",
          label: "email",
          class: "text-center",
        },
        {
          key: "Connected",
          label: "Connected At",
          class: "text-center",
        }
      ],
    };
  },
  computed: {
    formattedNumber() {
      return function (number) {
        return number.toLocaleString();
      };
    },
  },
  mounted() {
    this.getCountGlobalU()
    this.getCountUniqueU()
    this.getEventCount()
    this.getLast10Connection()
  },
  methods: {
    async getCountGlobalU() {
      await axios
        .get("/get-sum-users-per-day-global")
        .then(({ data }) => {
          this.countUsersGlobal = data.usersGlobal;
        })
        .catch((error) => {
          console.error(error)
        });

    },
    async getCountUniqueU() {
      await axios
        .get("/get-sum-users-per-day-unique")
        .then(({ data }) => {
          this.countUsersUnique = data.usersUnique;
        })
        .catch((error) => {
          console.error(error)
        });
    },
    async getEventCount() {
      await axios
        .get("/get-event-count")
        .then(({ data }) => {
          this.eventCount = data.eventCount;
        })
        .catch((error) => {
          console.error(error)
        });
    },
    async getLast10Connection() {
      await axios
        .get("/get-last-users-connection")
        .then(({ data }) => {
          this.users = this.addIndexToTable(data.data);
        })
        .catch((error) => {
          console.error(error)
        });
    },
    addIndexToTable(table) {
      const t = table.map((item, index) => {
        item.index = index + 1;
        return item;
      });
      return t;
    },
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";


.unique-card {
  cursor: pointer;
}
</style>
