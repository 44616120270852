<template>
  <div>
    <b-container>
      <b-row>
        <b-col>
          <b-card>
            <h1 class="mb-0">Dashboard</h1>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <users-connect />
  </div>
</template>
<script>
import SearchInputs from "@/components/analytics/SearchInputs.vue";
import { BContainer, BRow, BCol, BCard } from "bootstrap-vue";
import UsersConnect from "@/components/analytics/UsersConnect.vue";
import axios from "@axios";
export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BCard,
    UsersConnect,
    SearchInputs,
  },
  beforeRouteEnter(to, from, next) {
    axios.post("/isAdmin").then(({ data }) => {
      if (data.status === true) {
        next(true);
      } else if (data.status === false) {
        next("/error-404");
      }
    }).catch((error)=>{
      next("/login")
    });
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
